import Api from '@/Services/Api'

export default {

    async getEngare() {
        let response = await Api().get('/widgets/engare')
        if (!response || !response.status || response.status == 404 || response.data.error == "no-widget") {
            return 404
        } else {
            return response.data
        }
    }

}