<template>
  <div class="fill-height mainContainer">

    <side-menu @logout="logout"></side-menu>
    <top-app-bar class="topappbar"></top-app-bar>

    <v-main app class="backgroundAppColor fill-height" id="main">

      <v-img class="user-background" v-if="userBackground" :src="userBackground"></v-img>
      <v-expand-transition>
        <router-view></router-view>
      </v-expand-transition>

      <password-expiration v-if="$router.currentRoute.query.changePassword"></password-expiration>

    </v-main>

    <logout v-if="loginOut"></logout>
  </div>
</template>

<script>
import LoginService from '@/Services/LoginService'
import PlaneteOnlineService from "@/Services/PlaneteOnlineService"
import Vuex from "vuex"
import UserService from "@/Services/UserService"
import CryptoJS from "crypto-js"
import Store from "@/Store";
import service from "@/Services/TraductionService";
import CommunicationService from "@/Services/Communication";
import WidgetService from "@/Services/WidgetService";


export default {
  name: "Main",
  components: {
    SideMenu: () => import("@/Components/Commons/Interface/SideMenu/SideMenu"),
    TopAppBar: () => import("@/Components/Commons/Interface/TopAppBar/TopAppBar"),
    Logout: () => import("@/Components/Views/Home/Logout"),
    PasswordExpiration: () => import("@/Components/Views/Home/PasswordExpiration")
  },
  computed: {
    ...Vuex.mapState(["preferences", "informations", "communication", "widgetBoard"]),
    backgroundType() {
      if (this.preferences.background) {
        return this.preferences.background.type
      } else {
        return null
      }
    },
    userBackground() {
      if (this.backgroundType == "predefined") {
        return require("@/Assets/Images/Interface/Backgrounds/predefined-background-" + this.preferences.background.value + "-" + (this.$vuetify.theme.dark ? 'dark' : 'light') + ".png")
      } else if (this.backgroundType == "custom") {
        return this.preferences.background.value
      } else
        return null
    },
  },
  data() {
    return {
      loginOut: false,
      intervalCommunication: null
    }
  },
  methods: {

    sendStore: async function () {
      let response = await PlaneteOnlineService.postInterface(this.$store.state)
      this.$nError("Reset", response)
    },
    logout: async function () {
      this.loginOut = true
      let startScript = performance.now()
      let deconnected = await LoginService.logout()

      if (localStorage.getItem(CryptoJS.MD5("userToken"))) {
        localStorage.removeItem(CryptoJS.MD5("userToken"))
      }

      let endScript = performance.now()
      let time = endScript - startScript;

      // Délai de déconnexion pour la confiance utilisateur
      if (time >= 2000) time = 1999
      await this.sleep(2000 - time)

      this.$vuetify.theme.dark = false
      await this.$router.push({name: 'Login'})
      this.loginOut = false
    },
    getDatabaseNews: async function () {
      let news = await CommunicationService.getNews()
      news.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      this.$store.commit("resetNews", news)
    },
    installEngare: async function () {

      let isNotInstalled = this.widgetBoard.widgets.find(a => a.name == "engare") == null
      let engareWidgetHosts = [
          "engare-preprod.planett.net",
          "engare.planett.net"
      ]
      if (engareWidgetHosts.indexOf(location.hostname) !== -1) {
        if (isNotInstalled) {
          WidgetService.getEngare().then(response => {
            let hasEngare = response != 404
            if (hasEngare) {
              this.$store.state.widgetBoard.widgets.push({
                path: "/OtherWidgets/EnGare",
                name: "engare",
                position: {
                  x: 0,
                  y: 4
                },
                width: 3,
                height: 2,
                datas: {}
              })

              PlaneteOnlineService
                .postInterface(this.$_.pick(this.$store.state, ["widgetBoard"]))
                .then(() => {})
            }
          })
        }
      } else {
        if (!isNotInstalled) {
          let deletedItems = this.widgetBoard.widgets.splice(this.widgetBoard.widgets.findIndex(a => a.name == "engare"), 1)
          if (deletedItems) {
            PlaneteOnlineService
                .postInterface(this.$_.pick(this.$store.state, ["widgetBoard"]))
            .then(() => {})
          }
        }
      }
    }
  },
  async created() {

    // Chargement des informations de l'utilisateur
    const user = await UserService.getUser()
    Store.commit("setUser", {user: user})

    // Chargement des données de l'interface (si l'utilisateur s'y est déjà connecté une première fois)
    let response = await PlaneteOnlineService.getInterface()
    await this.$store.dispatch("resetStore", this.$_.pick(response, ["widgetBoard", "preferences"]))
    await this.installEngare()
    this.$uiSounds.state.preferences.soundOn = this.preferences.soundOn
    this.$uiSounds.state.preferences.sendMail = this.preferences.sendMail

    // Changement du thème de couleur
    this.$vuetify.theme.dark = this.preferences.theme

    // Chargement de la langue enregistrée par l'utilisateur ou paramétrée sur planete-online si elle n'existe pas
    this.loadLang()

    // Chargement des modules en fonction de la langue
    UserService.getModules(this.preferences.lang || this.informations.defaultLanguage.toLowerCase()).then(async (moduleResponse) => {
      if (moduleResponse) {
        let modules = Object.values(moduleResponse)
        modules.forEach(module => {
          module.slug = this.slugify(this.htmlDecode(module.name))
          module.subModules.forEach(subModule => {
            subModule.slug = this.slugify(this.htmlDecode(subModule.name))
          })
          module.subModules = module.subModules.filter(subModule => {
            return subModule.path != "" && subModule.path != "false"
          })
        })
        this.$store.commit("resetStore", {modules: modules})
      }
    })


    // Chargement de la photo de profil
    UserService.getPhoto().then((responsePhoto) => {
      if (responsePhoto) {
        this.$store.commit("setUser", {photo: responsePhoto})
      } else {
        this.$store.commit("setUser", {photo: require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')})
      }
    })


    // Chargement du fond d'écran si c'est un fond d'écran personnalisé. Si c'est un prédéfini, ne fait rien car la donnée est déjà enregistrée dans le store
    if (this.backgroundType != "predefined") {
      UserService.getWallpaper().then((responseWallpaper) => {
        if (responseWallpaper) {
          this.$store.commit("setUser", {background: responseWallpaper})
        }
      })
    }

    PlaneteOnlineService
        .postInterface(this.$_.pick(this.$store.state, ["informations"]))
        .then(()=>{});

    // Chargement des actualités en global pour pouvoir récupérer la liste de n'importe où
    let exclusionLists = await CommunicationService.getExclusionList()
    exclusionLists = exclusionLists.filter(a => a.active)
    this.$store.commit("resetExclusionListNews", exclusionLists.map(a => a.list))

    let excluded = []
    this.communication.exclusionLists.forEach(l => excluded = excluded.concat(l.map(a => a.name)))
    if (excluded.indexOf(this.getDns()) === -1) {
      await this.getDatabaseNews()
      clearInterval(this.intervalCommunication)
      this.intervalCommunication = setInterval(this.getDatabaseNews, 300000)
    }

  },
  async destroyed() {
    clearInterval(this.intervalCommunication)
  }
}
</script>

<style scoped>

#main {
  height: 100vh;
  overflow-y: scroll;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-bottom: 10em;
}


.mainContainer {
  position: relative;
}

.user-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

</style>
